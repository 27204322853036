@use '../vars' as *;
@use '../colours' as col;
@use '../typography.scss' as type;
@use './dark-mode.scss' as *;

@mixin link() {
  @include type.link();

  color: col.$link;
  cursor: pointer;
  padding: 1px !important;
  text-decoration: none;
  font-weight: bold;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: col.$link-hover;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &:focus {
    background-color: col.$keyboard-focus;
    color: col.$dark;
  }

  &:disabled {
    background-color: transparent;
    color: col.$link-disabled;
  }
}

.link {
  @include link();
}
