
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.tooltip {
  background-color: col.$light;
  border-radius: $border-radius;
  color: col.$body-text;
  display: flex;
  filter: drop-shadow(0 0.2rem 0.5rem rgba(0, 0, 0, 0.2));
  font-size: type.$button-size;
  padding: padding(0.5) padding(2) padding(0.6);
  place-items: center;
  position: absolute;
  width: max-content;
  z-index: 99999;

  .arrow {
    background-color: inherit;
    height: 1rem;
    position: absolute;
    visibility: hidden;
    width: 1rem;

    &::before {
      background-color: inherit;
      content: '';
      height: 1rem;
      position: absolute;
      transform: rotate(45deg);
      visibility: visible;
      width: 1rem;
    }

    &.top {
      bottom: -0.5rem;
    }

    &.bottom {
      top: -0.5rem;
    }

    &.left {
      right: -0.4rem;
    }

    &.right {
      left: -0.5rem;
    }
  }
}
