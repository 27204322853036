
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.checkbox-group {
  display: grid;
  flex-wrap: wrap;
  gap: spacing(2);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  &.vertical {
    flex-direction: column;
  }

  .label {
    font-weight: 400;
  }
}

.legend {
  @include util.base-input-label();

  margin-bottom: margin(2);
}
