// NOTE: If you add or remove a colour variable,
// you will need to update the _colourvars.module.scss file
// in the styles folder to match.

/* Backgrounds */
$bg: hsl(0, 0%, 100%);
$bg-dark: hsl(216, 81%, 6%);
$main-panel-bg: hsl(0, 0%, 100%);
$alt-panel-bg: hsl(160, 18%, 90%);

/* Brand Colours */
$primary: hsl(14, 100%, 58%);
$primary-darker: hsl(14, 100%, 44%);
$secondary: hsl(212, 100%, 10%);
$tertiary: hsl(170, 76%, 48%);

/* Dark and light base colours */
$dark: hsl(0, 0%, 7%);
$light: hsl(0, 0%, 100%);

/* Grey shades */
$grey-100: hsl(0, 0%, 98%);
$grey-200: hsl(0, 0%, 94%);
$grey-300: hsl(0, 0%, 90%);
$grey-400: hsl(0, 0%, 77%);
$grey-500: hsl(0, 0%, 60%);
$grey-600: hsl(0, 0%, 40%);
$grey-700: hsl(0, 0%, 20%);
$grey-800: hsl(0, 0%, 13%);
$grey-900: hsl(0, 0%, 7%);
$grey-warm: hsl(0, 0%, 97%);
/* Typography */
$body-text: hsl(216, 81%, 6%);
$body-text-inverse: hsl(0, 0%, 100%);

/* Statuses */
$warning: hsl(33, 88%, 36%);
$success: hsl(150, 100%, 26%);
$danger: hsl(8, 73%, 49%);
$info: hsl(214, 60%, 48%);
$error: $danger;

/* Inputs */
$input-bg: $bg;
$input-focus-bg: lighten($primary, 59%);
$input-border: $grey-500;
$input-border-focus: $primary;
$label-text: $body-text;
$input-text: $body-text;
$disabled-input-text: hsl(0, 5%, 30%);

/* Links */
/* Style also applies to text buttons */
$link: $primary-darker;
$link-hover: lighten($link, 20%);
$link-disabled: hsl(0, 5%, 46%);

/* Actions */
$action: $primary-darker;
$action-destroy: $danger;
$action-create: $success;
$action-disabled: hsl(0, 0%, 60%);

$action-hover: lighten($action, 15%);
$action-destroy-hover: lighten($action-destroy, 10%);
$action-create-hover: lighten($action-create, 10%);

$action-focus-bg: lighten($primary, 20%);
$action-destroy-focus-bg: lighten($action-destroy, 20%);
$action-create-focus-bg: lighten($action-create, 20%);

$action-text: hsl(0, 0%, 100%);

/* Shadows and borders */

$shadow: hsl(0, 0%, 0%);
$border: hsl(30, 3%, 87%);
$border-dark: hsl(0, 0%, 44%);

/* A11y compliance */

$keyboard-focus: hsl(45, 100%, 51%);

/**
* Utility classes
*/

.bg {
  background-color: $bg;
}

.bg-dark {
  background-color: $bg-dark;
}

.main-panel-bg {
  background-color: $main-panel-bg;
}

.alt-panel-bg {
  background-color: $alt-panel-bg;
}

.primary {
  color: $primary;
}

.primary-darker {
  color: $primary-darker;
}

.secondary {
  color: $secondary;
}

.tertiary {
  color: $tertiary;
}

.dark {
  color: $dark;
}

.light {
  color: $light;
}

.grey-100 {
  color: $grey-100;
}

.grey-200 {
  color: $grey-200;
}

.grey-300 {
  color: $grey-300;
}

.grey-400 {
  color: $grey-400;
}

.grey-500 {
  color: $grey-500;
}

.grey-600 {
  color: $grey-600;
}

.grey-700 {
  color: $grey-700;
}

.grey-800 {
  color: $grey-800;
}

.grey-900 {
  color: $grey-900;
}

.grey-warm {
  color: $grey-warm;
}

.body-text {
  color: $body-text;
}

.body-text-inverse {
  color: $body-text-inverse;
}

.warning {
  color: $warning;
}

.success {
  color: $success;
}

.danger {
  color: $danger;
}

.info {
  color: $info;
}

.error {
  color: $error;
}

.input-bg {
  background-color: $input-bg;
}

.input-focus-bg {
  background-color: $input-focus-bg;
}

.input-border {
  border-color: $input-border;
}

.input-border-focus {
  border-color: $input-border-focus;
}

.label-text {
  color: $label-text;
}

.input-text {
  color: $input-text;
}

.input-text:disabled {
  color: $disabled-input-text;
}

.link {
  color: $link;
}

.link:hover {
  color: $link-hover;
}

.link:disabled {
  color: $link-disabled;
}

.action {
  color: $action;
}

.action-destroy {
  color: $action-destroy;
}

.action-create {
  color: $action-create;
}

.action:disabled {
  color: $action-disabled;
}

.action:hover {
  color: $action-hover;
}

.action-destroy:hover {
  color: $action-destroy-hover;
}

.action-create:hover {
  color: $action-create-hover;
}

.action-focus-bg {
  background-color: $action-focus-bg;
}

.action-destroy-focus-bg {
  background-color: $action-destroy-focus-bg;
}

.action-create-focus-bg {
  background-color: $action-create-focus-bg;
}

.action-text {
  color: $action-text;
}

.shadow {
  box-shadow: 0 4px 6px -1px $shadow, 0 2px 4px -2px $shadow;
}

.border {
  border-color: $border;
}

.border-dark {
  border-color: $border-dark;
}

.keyboard-focus:focus {
  outline-color: $keyboard-focus;
}
