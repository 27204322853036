
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.padding {
  &.vertical {
    padding-bottom: padding(2);
    padding-top: padding(2);
  }

  &.horizontal {
    padding-left: padding(3);
    padding-right: padding(3);
  }

  @include util.mq(bp.$medium-small) {
    &.horizontal {
      padding-left: padding(6);
      padding-right: padding(6);
    }
  }
}
