
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.input-container {
  @include util.statuses('background', '.status-message');

  display: flex;
  flex-direction: column;
  padding: padding(0.3);
  position: relative;

  &.hidden {
    display: none;
  }

  &:hover {
    .status-message {
      display: flex;
    }
  }

  .status-message {
    align-items: center;
    border-radius: 3px;
    color: col.$light;
    display: none;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.1));
    font-size: type.$small-text-size;
    gap: spacing(0.5);
    left: spacing(0.4);
    margin-top: margin(0.5);
    padding: padding(0.5);
    position: absolute;
    top: 100%;
    z-index: 100;

    &::before {
      background-color: inherit;
      content: '';
      height: 1rem;
      left: 4px;
      position: absolute;
      top: -5px;
      transform: rotate(45deg);
      visibility: visible;
      width: 1rem;
    }
  }

  .icon {
    height: 20px;
    width: 20px;
  }

  .helper-text-top,
  .helper-text-bottom {
    @include type.input-label();

    color: col.$grey-600;
  }

  .helper-text-top {
    margin-bottom: margin(1);
  }

  .helper-text-bottom {
    margin-top: margin(0.5);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .label-hidden {
    display: none;
  }
}

.tooltip {
  position: relative;
}
