// A list of standard breakpoints
// Additional breakpoints may be applied on a component-level
// NOTE: If you are looking for the media query (mq) mixin, it is in styles/utilities/responsive.scss

$extra-small: 321px;
$small: 420px;
$medium-small: 600px;
$medium: 1024px;
$medium-large: 1200px;
$large: 1280px;
$extra-large: 1440px;
$extra-extra-large: 1920px;
