@use '../vars' as *;
@use '../colours' as col;
@use 'focus-visible' as *;

$statuses: (
  error: col.$error,
  success: col.$success,
  warning: col.$warning,
  info: col.$info
);

@mixin statuses($type, $child: '') {
  @each $status, $color in $statuses {
    &.status-#{$status} #{unquote($child)} {
      @if $type == 'border' {
        border-color: $color;
      }

      @if $type == 'color' or $type == 'colour' {
        color: $color;
      }

      @if $type == 'background' {
        background-color: $color;
      }

      @if $type == 'background-faded' {
        background-color: lighten($color, 40%);
      }
      // Use this if you want the element to have a hover state
      @if $type == 'border-hover' {
        border-color: $color;

        &:hover {
          @if lightness($color) > 50 {
            border-color: darken($color, 10%);
          } @else {
            border-color: lighten($color, 10%);
          }

          border-color: $color;
        }
      }

      @if $type == 'color-hover' or $type == 'colour-hover' {
        color: $color;

        &:hover {
          @if lightness($color) > 50 {
            color: darken($color, 10%);
          } @else {
            color: lighten($color, 10%);
          }
        }
      }

      @if $type == 'background-hover' {
        background-color: $color;

        &:hover {
          @if lightness($color) > 50 {
            background-color: darken($color, 10%);
          } @else {
            background-color: lighten($color, 10%);
          }
        }
      }

      /** Quite a specific use case where you want the border to lighten/darken
      /* on hover but you also want a subtle light background colour as well
      /*/
      @if $type == 'background-border-hover' {
        border-color: $color;

        &:hover {
          background-color: lighten($color, 45%);

          @if lightness($color) > 50 {
            border-color: darken($color, 10%);
          } @else {
            border-color: lighten($color, 10%);
          }
        }
      }

      @if $type == 'focus' {
        @include focus-visible($color);
      }
    }
  }
}
