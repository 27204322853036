
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.base-layout {
  background: col.$grey-300;
  background-color: col.$bg;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-self: center;
  min-height: 100%;
  position: relative;
  width: 100%;
}

.main-content {
  background-color: col.$light;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  max-width: bp.$large;
  position: relative;
  width: 100%;
}

.skip-link {
  @include type.body-text();

  background-color: col.$primary-darker;
  color: col.$light;
  display: block; /* for screen readers */
  font-weight: bold;
  height: 35px;
  left: 0;
  line-height: 1;
  padding: padding(0.8);
  position: absolute;
  text-align: center;
  top: -35px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 1000;

  &:focus {
    box-shadow: $drop-shadow;
    top: 0;
  }
}

.nav-bar {
  background-color: col.$secondary;
  color: col.$light;
  display: flex;
  justify-content: center;
  padding: padding(1.5) padding(4);

  @include util.mq(bp.$medium-large) {
    justify-content: flex-end;
  }

  ul {
    display: flex;
    flex-direction: row;
    gap: spacing(1);
  }

  li:not(:last-child) {
    padding-right: padding(1);
    border-right: 1px solid col.$grey-400;
  }

  a {
    @include util.link();
    display: block;
    color: col.$light;
  }
}
