
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.main {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  margin: 0 auto;
  margin-bottom: margin(4);
  width: 100%;

  & > div:last-child > :global(.needs-bottom-margin) {
    margin-bottom: spacing(2);
  }
}
