
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.radio-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: spacing(2);
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  &.vertical {
    flex-direction: column;
  }

  .label {
    font-weight: 400;
  }
}

.legend {
  @include util.base-input-label();

  margin-bottom: margin(1);
}
