
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.input {
  @include util.base-input;

  align-items: center;
  appearance: none;
  border-color: col.$primary;
  border-radius: 4px;
  border-width: 2px;
  cursor: inherit;
  display: grid;
  height: 21px;
  padding: 0;
  vertical-align: middle;
  width: 21px;

  &::before {
    background-color: transparent;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    content: '';
    height: 60%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  }

  &:focus {
    border-color: col.$primary;
    outline: none;
  }

  &:checked {
    background-color: col.$primary;

    &::before {
      background-color: col.$light;
    }
  }

  &:checked:disabled {
    &::before {
      background-color: col.$label-text;
    }
  }

  &:disabled {
    background-color: col.$grey-300;
    border-color: col.$grey-400;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.icon-container {
  position: relative;

  svg {
    height: 100%;
    left: 0;
    padding: 2px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--hidden {
    opacity: 0;
    position: absolute;
  }
}
