
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.page-error {
  section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    justify-content: center;
    padding: padding(4) 0;
  }

  h1 {
    @include type.heading-large();

    font-weight: 900;
    margin-bottom: margin(2);
  }

  hr {
    border: 0;
    border-top: 1px solid col.$grey-300;
    margin: margin(2) 0;
    width: 100%;
  }
}
